@import './variables';
@import './antd';

body {
  margin: 0;
  font-family: var(--mainFont), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

h1 {
  margin: 0;
}

#root {
  height: 100%;
}

.ulex-page-title {
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
  color: var(--main-color);
  margin-bottom: 64px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 30px;
  }
}
