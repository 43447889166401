.main-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;

  &__content {
    padding: 20px 0;
    flex: 1;
    height: 100%;
    width: calc(100% - 120px);
    margin: 0 auto;
    height: calc(100% - 72px - 59px); // - header & footer

    @media screen and (max-width: 1024px) {
      width: calc(100% - 40px);
    }
  }

  &__footer {
    padding: 20px 0;
    color: #808080;
    width: calc(100% - 120px);
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      width: calc(100% - 40px);
    }
  }
}
