.wrapper-header-ulex {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  z-index: 1001;
}

.content-header-ulex {
  width: calc(100% - 120px);
  height: 72px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.container-header {
  display: flex;
  align-items: center;
  height: 100%;
}

.item-header-logos {
  display: flex;
  align-items: center;
  margin-right: 5px;
  user-select: none;
}

.logo-separator {
  font-family: var(--mainFont);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #808080;
  margin-top: 2px;
  padding: 0 5px;
}

.item-header-nav {
  margin-left: 25px;
  cursor: pointer;
  font-family: var(--mainFont);
  color: var(--main-color);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;

  &:hover,
  &.active {
    color: #FC6B58;
  }
}

.item-header-search {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;

  svg {
    margin: 0 8px;
    cursor: pointer;

    &:hover {
      fill: #FC6B58;
    }
  }
}

.active-item-header {
  height: 2px;
  width: 100%;
  background-color: red;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.item-header-menu-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  display: none;
  align-items: center;
  height: 100%;

  svg {
    margin: 0 8px;
    cursor: pointer;

    &:hover {
      fill: #FC6B58;
    }
  }
}

.user-is-authorized {
  display: flex;
  height: 100%;
  align-items: center;
}

.item-header-my-statements {
  height: fit-content;
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 11px 14px;
  margin: 0 10px;
  cursor: pointer;
  font-family: var(--mainFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;

  svg {
    margin-left: 8px;
    stroke: black;
  }

  &:hover {
    color: #FC6B58;
    border-color: #FC6B58;

    svg {
      fill: #FC6B58;
      stroke: #FC6B58;
    }
  }
}

.title-my-statements {
  width: 75px;
}

.icon-my-statements {
  margin-left: 8px;
}

.user-container-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.welcone-user-is-authorized {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;

}

.welcome-user-header {
  font-family: var(--mainFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #606060;
}

.name-user-header {
  font-family: var(--mainFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
}

.avatar-user-is-authorized {
  margin-left: 8px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  background: #FC6B58;
  box-sizing: border-box;
}

.item-header-user-login {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}


.user-is-not-authorized-mobile {
  display: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  background: #EAEAEA;
  box-sizing: border-box;
  margin-left: 8px;

  img {
    width: 20px;
    height: 20px;
  }

}

.user-is-not-authorized {
  display: flex;
  margin-left: 8px;

  font-family: var(--mainFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
}

.item-user-is-not-authorized-1 {
  padding: 11px 32px;
  border: 1px solid var(--border-color);
  border-radius: 6px 0px 0px 6px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    border-color: #FC6B58;
    color: #FC6B58;
  }
}

.item-user-is-not-authorized-2 {
  padding: 11px 32px;
  background: #000000;
  border-radius: 0px 6px 6px 0px;
  border: 1px solid black;
  box-sizing: border-box;
  color: white;
  cursor: pointer;

  &:hover {
    border-color: #FC6B58;
    background-color: #FC6B58;
  }
}

.item-header-profile {
  display: flex;
  align-items: center;
  text-align: right;

  &>div:first-child {
    margin: 0 12px;
  }

  p {
    margin-bottom: 0;
    font-weight: 500;
  }

  span {
    color: #606060;
  }

  .ant-avatar-string {
    color: white;
  }
}

@media screen and (max-width: 1024px) {
  .content-header-ulex {
    width: calc(100% - 40px);
  }
  .item-header-nav {
    display: none;
  }
  .item-header-menu-mobile {
    display: flex;
  }
  .item-header-my-statements {
    display: none;
  }
  .welcone-user-is-authorized {
    display: none;
  }
  .user-is-not-authorized-mobile {
    display: flex;
  }
  .user-is-not-authorized {
    display: none;
  }
  .logo-separator {
    font-size: 10px;
    line-height: 12px;
    margin-top: 1px;
  }
}


