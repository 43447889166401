.ant-form-item-control-input-content {
  &>.ant-input,
  &>.ant-input-affix-wrapper {
    height: 48px;
    padding: 12px 16px;
    border: none;
    border-bottom: 1px solid #DFDFDF;
    border-radius: 0px;

    &:focus {
      box-shadow: none;
      border-color: var(--main-color);
    }
  }

  &>.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: var(--main-color);
  }
}
