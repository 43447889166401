.loader{
  &:not(.no-parents){
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    transform: perspective(1px);
    display: flex;
    align-items: center;
    justify-content: center;

    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#1E2534, 0.1);
      backdrop-filter: blur(5px);
      opacity: 1;
      pointer-events: none;
      z-index: -1;
    }
  }

  &-parent{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    #logo-animation{
      width: 200px;
    }
  }

  &-wrapper-small {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 300ms ease;
    opacity: 0;
    pointer-events: none;
    z-index: 999999999;
    &.active{
      pointer-events: auto;
      opacity: 1;
    }
  }

  &-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 300ms ease;
    opacity: 0;
    pointer-events: none;
    z-index: 9999;
    &.active{
      pointer-events: auto;
      opacity: 1;
    }
  }
}
#logo-animation {
  width: 180px;
  height: 180px;
  img {
    animation: 3s spinLoader infinite linear;
  }
}
.loader-dots {
  width: 180px;
  height: 180px;
  position: absolute;
  border-radius: 50%;
  animation: 7s spinLoader infinite linear;
  span {
    width: 14px;
    height: 14px;
    background: #fff;
    position: absolute;
    border-radius: 50%;
    &:nth-child(1) {
      left: 0;
      top: 50%;
      margin-top: -7px;
      animation: 2s scaleDot infinite linear;
    }
    &:nth-child(2) {
      left: 22px;
      top: 22px;
      animation: 2s scaleDot infinite linear;
      animation-delay: .25s;
    }
    &:nth-child(3) {
      top: 0;
      left: 50%;
      margin-left: -7px;
      animation: 2s scaleDot infinite linear;
      animation-delay: .5s;
    }
    &:nth-child(4) {
      right: 22px;
      top: 22px;
      animation: 2s scaleDot infinite linear;
      animation-delay: .75s;
    }
    &:nth-child(5) {
      right: 0;
      top: 50%;
      margin-top: -7px;
      animation: 2s scaleDot infinite linear;
      animation-delay: 1s;
    }
    &:nth-child(6) {
      right: 22px;
      bottom: 22px;
      animation: 2s scaleDot infinite linear;
      animation-delay: 1.25s;
    }
    &:nth-child(7) {
      bottom: 0;
      left: 50%;
      margin-left: -7px;
      animation: 2s scaleDot infinite linear;
      animation-delay: 1.5s;
    }
    &:nth-child(8) {
      left: 22px;
      bottom: 22px;
      animation: 2s scaleDot infinite linear;
      animation-delay: 1.75s;
    }
  }
}

@keyframes scaleDot {
  50% {
    transform: scale(2.3, 2.3);
  }
  100% {
    transform: scale(1,1);
  }
}


@keyframes spinLoader {
  100%{
    transform: rotate(360deg);
  }
}
